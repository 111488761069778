import { Typography } from "@mui/material";

const Logging = () => {
  return (
    <>
      <Typography>Logging</Typography>
    </>
  );
};

export default Logging;
